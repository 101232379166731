.loading-container {
    position: fixed;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background-color: #171D21;
    text-align: center;
    z-index: 999999;
    border-radius: 8px;
    opacity: 1;
    transition: 0.3s;
    transform: scale(0.75);
}

.loader {
    position: relative;
    margin: 15px auto;
    font-size: 4px;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #FFF;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

.loader,
.loader:after {
    width: 36px;
    height: 36px;
    border-radius: 50%;
}

.login-form .loader,
.login-form .loader:after,
.modal-footer .loader {
    width: 16px;
    height: 16px;
}

.login-form .loader,
.modal-footer .loader {
    margin: 1px auto;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
