.mapboxgl-map {
    position: unset !important;
}

.data-map {
    margin-top: 32px;
    margin-bottom: 300px;
    position: relative;
}

.error-message {
    color: #BD2137;
    font-size: 0.85rem;
    font-weight: bold;
    text-align: center;
    padding: 10px;
}

.mapboxgl-ctrl-attrib {
    display: none !important;
}

Table.search-examples{
    font-size: 12px;
    background-color: #fbfbfb;
    margin: -5px 0 0 0;
    border-radius:5px;
}

.dates-parent {
    margin-top: 25px;
}

.test-search-text-button-parent {
   text-align: center;
   margin-top: 20px;
}

.test-search-text-button {
    background-color: #FFC300;
    padding: 10px;
    color: #000000;
    border: none;
    border-radius: 4px;
    font-weight: 700;
    width: 100%;
    max-width: 400px;
    text-transform: uppercase;
    font-size: 11px;
    margin: 5px auto 20px auto;
}


