.title-text-input {
    width: 100%;
    padding: 5px;
    color: #333333;
    font-size: 15px;
    background-color: #EBEBEB !important;
}

input[type="file"] {
    padding-left: 300px;
    margin-right: -300px;
}
