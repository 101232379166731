.badge-primary {
    background-color: #171D21;
}

.mydata-page {
    margin-top: 154px;
    min-width: 690px;
}

td img {
    max-height: 48px;
}

.table td, .table th {
    border-top: 0 solid #dee2e6;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 0 solid #dee2e6;
    padding-bottom: 24px;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #FBFBFB;
}

.mydata-page table tbody tr {
    border-radius: 80px !important;
}

.mydata-page .table {
    font-weight: 400;
    font-size: 12px;
    color: #2B3841;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -16px;
}

.mydata-page .table .col-id {
    width: 72px;
}

.mydata-page .table .col-satisfied {
    width: 48px;
    text-align: center;
}

.mydata-page .table .col-is-starred, .mydata-page .table .col-created-at {
    width: 64px;
}

.mydata-page .table .col-service-type, .mydata-page .table .col-location, .mydata-page .table .col-service-point, .mydata-page .table .col-user, .mydata-page .table .col-df1 {
    width: 96px;
    overflow: hidden;
}

.mydata-page .table .col-df1 button {
    width: 100%;
    padding: 7px 10px;
}

.mydata-page .table .col-df1 button + button {
    margin-top: 5px;
}

.mydata-page .table .col-idea em {
    background-color: #FFC300;
    border-radius: 4px;
    color: #ffffff;
    padding:3px;
}

.page-item.active .page-link {
    background-color: #ffc300;
    border: 1px #ffc300 solid;
}

@media (max-width: 1106px) {
    .mydata-page {
       margin-top: 194px;
    }
}

@media (max-width: 992px) {
    .mydata-page {
       margin-top: 94px;
    }

    .mydata-page .table .col-service-type, .mydata-page .table .col-location {
        display: none;
    }
}

@media (min-width: 1200px) {
    .container-fluid.desktop-non-fluid {
        max-width: 1140px;
    }
}
