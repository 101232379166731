
.login-page{
    min-height: calc(100vh - 248px);
}

.login-page h3{
    margin-top: 0px;
    margin-bottom: 16px;
}

.login-page input {
    padding: 16px;
    background-color: #F5F5F5;
    border-radius: 4px;
    border: 0px !important;
    margin-bottom: 16px;
    width: 100%;
}

.login-page button{
    width: 100%;
}

.login-page .login-form{
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 24px 18px -10px rgba(23,29,33,0.08);
    padding: 24px;
    position: relative;
    top: 50%;
    margin-top: -50%;
}

.login-form .alert {
    margin-top: 16px;
    margin-bottom: 0 !important;
}

.login-row{
    height: calc(100vh - 248px);
}
